import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { ModelFrom } from 'vault-client/utils/type-utils';
import OrganizationsOrganizationDetailsAndMembersRoute from 'vault-client/routes/organizations/organization/details-and-members';
import { EntityMember } from 'vault-client/types/graphql-types';

export default class OrganizationsOrganizationDetailsAndMembersController extends Controller {
	@service permissions: any;
	settingsRoutePath: string = 'organizations.organization.settings';

	@tracked memberIdToRemove: string | null = null;
	@tracked memberDataToEdit: unknown;
	@tracked sorts = { valuePath: 'email', isAscending: true };

	declare model: ModelFrom<OrganizationsOrganizationDetailsAndMembersRoute>;

	get organization() {
		return this.model.data?.Organization;
	}

	get columns() {
		let baseColumns = [
			{
				id: '890f3ee9-a8ef-46fc-b145-6da0253d0d47',
				name: 'First Name',
				valuePath: 'User.firstName',
				minWidth: 110,
				cellComponent: 'String',
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '057f7e72-afc2-4211-b4f1-e5d063f32691',
				name: 'Last Name',
				valuePath: 'User.lastName',
				cellComponent: 'String',
				minWidth: 140,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5c0f1de7-c283-468a-a155-8b24753a6e61',
				name: 'Email',
				valuePath: 'email',
				cellComponent: 'String',
				minWidth: 215,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9d1bb2ba-f27d-4c8e-82ca-521922e2f747',
				name: 'Insurance',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'fd2a4459-7998-4892-93ae-71c2e20304ca',
						name: 'Read',
						valuePath: 'canReadInsurance',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'bb058058-0f7f-482d-ac87-f96bb6c88d3c',
						name: 'Write',
						valuePath: 'canWriteInsurance',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '6b243caa-ffa1-436b-96cb-9207a0fc81c4',
				name: 'Operations',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'c6726212-c0e2-43c1-94ee-49b9d5695252',
						name: 'Read',
						valuePath: 'canReadOperations',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '51106bd5-94c6-4c7b-83dd-2f9f05699a01',
						name: 'Write',
						valuePath: 'canWriteOperations',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'db0e3791-2389-46e8-9d4d-e6f90efda95f',
				name: 'Brokerage',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'c0460920-d398-4fdf-b36c-377b87e7cf98',
						name: 'Read',
						valuePath: 'canReadBrokerage',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '81b8cb8f-661c-4876-95f3-12594dd02a46',
						name: 'Write',
						valuePath: 'canWriteBrokerage',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'ea77fa91-b1d7-40eb-875e-775056de1798',
				name: 'Crop / Feed Plans',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '143b0e8a-59de-4b17-b059-d26074597d1f',
						name: 'Read',
						valuePath: 'canReadFeedAndCropPlan',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'a4ac30f2-8dc4-426f-b5da-5d2b43d08d69',
						name: 'Write',
						valuePath: 'canWriteFeedAndCropPlan',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '7a8e06a3-01ad-4a74-9899-8d7c9050695b',
				name: 'Swap',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '3e5b48ca-fe0e-4855-a68c-49ae11f63ef7',
						name: 'Read',
						valuePath: 'canReadSwap',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '9abb51d5-15f2-413c-b4b2-b25789b76cdf',
						name: 'Write',
						valuePath: 'canWriteSwap',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '92e11443-676d-43c8-93e2-9f2c6aa469ed',
				name: 'Organization',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '9cc91302-49bb-4eb8-802b-c0976d0ffa7a',
						name: 'Read',
						valuePath: 'canReadOrganization',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '9e47d638-2eb9-48d6-8c64-33a8c1f0aa95',
						name: 'Write',
						valuePath: 'canWriteOrganization',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '4cd85bd2-9c6f-4ae9-bbc9-2fe7c6d12cb5',
				name: 'Business',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '9e557c54-a73e-4929-9770-37f9e9d4ff88',
						name: 'Read',
						valuePath: 'canReadBusiness',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'e5eb9881-f5a8-4f12-bfa8-369854f856eb',
						name: 'Write',
						valuePath: 'canWriteBusiness',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '0962925f-a2d9-4de9-b53b-9ea83c7b7f40',
				name: 'EntityGroup',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '1cbdccbb-5364-45ab-b7b5-76542995e18c',
						name: 'Read',
						valuePath: 'canReadEntityGroup',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '4edd51d7-40db-42a8-882a-d11103e83f02',
						name: 'Write',
						valuePath: 'canWriteEntityGroup',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'f5e39d7b-8c12-4a28-86a7-c1e542e54d8a',
				name: 'Maternity Warden',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'c3e2a9b1-7f84-42d6-9e23-f8a510d76e3a',
						name: 'Read',
						valuePath: 'canReadMaternityWarden',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'd4f6e8c2-9a35-48b7-a1d4-e7c9b2f5a8d0',
						name: 'Write',
						valuePath: 'canWriteMaternityWarden',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '93aca4b1-4f28-4bf9-aecd-0b7561fd3d37',
				name: '',
				minWidth: 80,
				textAlign: 'center',
				cellComponent: 'Button',
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Edit',
					fn: this.editMember,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5f496e1b-6104-40cb-8bb0-41fd59b3fde0',
				name: '',
				minWidth: 130,
				textAlign: 'center',
				cellComponent: 'Button',
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Remove',
					fn: this.removeMember,
				},
				isFixed: '',
				isVisible: true,
			},
		];

		// Remove button for external users.
		if (!this.model.data?.Organization?.hasWriteAccess) {
			baseColumns = baseColumns.filter((column) => column.id != '5f496e1b-6104-40cb-8bb0-41fd59b3fde0');
		}

		return baseColumns;
	}

	get members() {
		const unsortedMembers = this.organization?.Members.filter((member: EntityMember) => {
			if (!member.User && !member.userEmail) {
				return false;
			}
			return true;
		}).map((member: any) => {
			const obj = { ...member };
			obj.email = member.User?.email ?? member.userEmail;
			return obj;
		});

		const sortedMembers = unsortedMembers?.slice();
		sortedMembers?.sort((a, b) => {
			let valueA = '';
			let valueB = '';
			switch (this.sorts.valuePath) {
				case 'lastName':
					valueA = a.User?.lastName?.toLowerCase();
					valueB = b.User?.lastName?.toLowerCase();
					break;
				case 'email':
					valueA = a.User?.email;
					valueB = b.User?.email;
					break;
				default:
					valueA = a.User?.firstName?.toLowerCase();
					valueB = b.User?.firstName?.toLowerCase();
					break;
			}

			if (this.sorts.isAscending) {
				if (valueA < valueB) return -1;
				if (valueA > valueB) return 1;
				return 0;
			} else {
				if (valueA > valueB) return -1;
				if (valueA < valueB) return 1;
				return 0;
			}
		});
		return sortedMembers;
	}

	@action
	removeMember(row: any) {
		this.memberIdToRemove = row.id;
	}

	@action
	editMember(row: any) {
		this.memberDataToEdit = { ...row, currentUserPermissions: { ...this.organization?.CurrentUserPermissions } };
	}

	@action
	closeRemoveMemberModal() {
		this.memberIdToRemove = null;
	}

	@action
	closeEditMemberModal() {
		this.memberDataToEdit = null;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations-organization-details-and-members-controller': OrganizationsOrganizationDetailsAndMembersController;
	}
}
