import Route from '@ember/routing/route';
import { OrganizationEntity, Query_CustomerArgs } from 'vault-client/types/graphql-types';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';

const GET_ORGANIZATION = gql`
	query Organization($id: String!) {
		Organization(id: $id) {
			id
			name
			createdAt
			updatedAt
			hasWriteAccess
			Members {
				id
				createdAt
				updatedAt
				userEmail
				canReadBrokerage
				canWriteBrokerage
				canReadInsurance
				canWriteInsurance
				canReadFeedAndCropPlan
				canWriteFeedAndCropPlan
				canReadOperations
				canWriteOperations
				canReadSwap
				canWriteSwap
				canReadOrganization
				canWriteOrganization
				canReadBusiness
				canWriteBusiness
				canReadEntityGroup
				canWriteEntityGroup
				canReadMaternityWarden
				canWriteMaternityWarden
				User {
					id
					firstName
					lastName
					email
				}
			}
			CurrentUserPermissions {
				canReadBrokerage
				canWriteBrokerage
				canReadInsurance
				canWriteInsurance
				canReadFeedAndCropPlan
				canWriteFeedAndCropPlan
				canReadOperations
				canWriteOperations
				canReadSwap
				canWriteSwap
				canReadOrganization
				canWriteOrganization
				canReadBusiness
				canWriteBusiness
				canReadEntityGroup
				canWriteEntityGroup
				canReadMaternityWarden
				canWriteMaternityWarden
			}
		}
	}
`;

type GetOrganizationQuery = {
	__typename?: 'Query';
	Organization: OrganizationEntity;
};

export default class OrganizationsOrganizationDetailsAndMembersRoute extends Route {
	@tracked variables: Query_CustomerArgs = { id: '' };

	getCustomer = useQuery<GetOrganizationQuery, Query_CustomerArgs>(this, () => [GET_ORGANIZATION, { variables: this.variables }]);

	async model() {
		const params = this.paramsFor('organizations.organization') as { id: string };
		this.variables = { id: params.id };
		await this.getCustomer.promise;

		return this.getCustomer;
	}
}
